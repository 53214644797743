@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gmarketLight {
  font-family: "GmarketSansLight", sans-serif;
  font-weight: 300;
}

.gmarketMedium {
  font-family: "GmarketSansMedium", sans-serif;
  font-weight: 500;
}

.tenada {
  font-family: "Tenada", sans-serif;
}

.basic {
  font-family: "Basic", sans-serif;
}
