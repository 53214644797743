@import url("//fonts.googleapis.com/earlyaccess/notosanskr.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GmarketSansLight";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansLight.woff")
    format("woff");
  font-weight: 300; /* Light weight */
  font-style: normal;
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tenada";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2210-2@1.0/Tenada.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Basic";
  src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-2@1.0/HakgyoansimBareonbatangB.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;

  font-family: "GmarketSansMedium", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 44%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 72% 51%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
    --font-heading: "GmarketSansMedium", sans-serif;
    --font-body: "GmarketSansMedium", sans-serif;
  }
}

@layer base {
  html,
  body {
    width: 100%; /* 전체 너비를 100%로 설정 */
    overflow-x: hidden; /* 가로 스크롤 숨기기 */
  }

  * {
    box-sizing: border-box; /* 모든 요소에 border-box 적용 */
    @apply border-border; /* Tailwind의 border 스타일 적용 */
  }

  body {
    @apply bg-background text-foreground font-body;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }
}

/* globals.css */
/* slick-dots 스타일을 슬라이더 위로 올리기 */
.slick-dots {
  bottom: 10px !important; /* 필요에 따라 위치 조정 */
  z-index: 10; /* 슬라이더 위에 나타나도록 z-index 설정 */
}

.slick-dots li button:before {
  color: #92c5fd !important; /* 점 색상 변경 */
  opacity: 1; /* 색상을 확실히 보이게 설정 */
}

/* globals.css 또는 tailwind.config.css */
.custom-underline {
  @apply underline;
  text-decoration-color: #92c5fd; /* 원하는 색상으로 변경 */
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 10ms;
}
